
@tailwind base;
@tailwind components;
@tailwind utilities;

body::-webkit-scrollbar{
  display: none;
}

#Navbar{
  /* background: rgb(250,214,69);
  background: linear-gradient(180deg, rgba(250,214,69,1) 0%, rgba(253,250,29,1) 50%, rgba(255,192,76,1) 100%); */

  /* background-color: rgb(0, 64, 255); */
  background-color: rgb(0, 0, 0);
}

/* #Navbar .logo-name{
  background-image: linear-gradient(60deg, #0055ff, #ff00e1);
  background-clip: text;
  color: transparent;
} */

#main{
  /* background: rgb(170,186,255);
background: linear-gradient(90deg, rgba(170,186,255,1) 0%, rgba(255,176,132,1) 50%, rgba(252,161,255,1) 100%); */
  /* background-color: rgb(144, 168, 238); */

  /* blue + gold */
  /* background: rgb(146,178,255);
background: linear-gradient(180deg, rgba(146,178,255,1) 0%, rgba(255,221,92,1) 100%); */

/* background-color: rgb(85, 97, 229); */
/* color: #36454f; */
background-color: #36454f;
color: white;

}

.service-container{
  /* background: rgb(255, 228, 118); */
  background: linear-gradient(180deg, rgb(224, 182, 17) 0%, rgb(210, 207, 19) 50%, rgb(182, 133, 44) 100%);
}

.gold-gradient{
  background-image: linear-gradient(60deg, rgb(224, 182, 17), rgb(255, 166, 0));
  background-clip: text;
  color: transparent;
}

#Footer{
  background-color: rgb(81, 81, 81);
  color: white;
  /* background-image: linear-gradient(to right, rgb(213, 184, 184), rgb(194, 185, 167), rgb(209, 209, 178), rgb(177, 205, 177), rgb(180, 180, 207), rgb(192, 176, 203), rgb(205, 178, 205)); */
}
