.each-category:hover .each-category-hover-img{
    opacity: 1;
}

.each-category:hover .each-category-hover-border{
    border: 5px;
    border-style: solid;
    border-color: rgb(17, 255, 0);
    border-radius: 10px;
    /* color: rgb(251, 255, 0); */
}

/* .each-category-hover-border{
    border: 4px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
} */